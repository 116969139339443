import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import CustomerBookings from "../components/CustomerBookings";

function Dashboard() {
  return (
    <>
      <ResponsiveAppBar shopData={{ companyDetails: { name: "" } }} />
      <CustomerBookings />
    </>
  );
}

export default Dashboard;
