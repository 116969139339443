import { Avatar } from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function StaffList({
  staffList,
  setSelectedStaff,
  setMainLayout,
  stringAvatar,
  showHeader = true,
}) {
  return (
    <>
      {showHeader && <h2 className="header">Staff</h2>}
      <div className="staff-list">
        {staffList.length > 0 && //shopData.staffDetailsList
          staffList.map((staff) => {
            return (
              <div
                className="staff-wrapper"
                key={staff.staffId}
                onClick={() => {
                  setSelectedStaff(staff);
                  setMainLayout((prev) => prev + 1);
                }}
              >
                <div className="details">
                  <Avatar {...stringAvatar(staff.name)} />
                  <p>{staff.name}</p>
                </div>
                <ChevronRightIcon />
              </div>
            );
          })}
      </div>
    </>
  );
}

export default StaffList;
