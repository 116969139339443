import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosMainHelper";
import coverImage from "../assets/login-main.jpg";
import "./Auth.css";
import { AuthContext } from "../context/AuthContext";

function SignUp() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    // console.log("window.location.host",);
    if (authCtx.isAuthenticated) {
      navigate("/home");
    }
  }, []);

  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput("", (value) => value !== "");

  let {
    enteredInput: enteredPassword,
    inputInvalid: passwordInputIsInvalid,
    blurHandler: passwordInputBlurHandler,
    changeHandler: passwordInputChangeHandler,
  } = useInput("", (value) => value !== "" && value.length >= 8);

  let {
    enteredInput: enteredName,
    inputInvalid: nameInputIsInvalid,
    blurHandler: nameInputBlurHandler,
    changeHandler: nameInputChangeHandler,
  } = useInput("", (value) => value !== "");

  const signUpHandler = async (e) => {
    e.preventDefault();

    nameInputBlurHandler();
    emailInputBlurHandler();
    passwordInputBlurHandler();

    if (!enteredName || !enteredEmail || !(enteredPassword.length >= 8)) {
      return;
    }

    try {
      const response = await axiosInstance.post("auth/customer/signup", {
        email: enteredEmail,
        password: enteredPassword,
        name: enteredName,
      });
      if (response.status === 200) {
        toast.success("Sign up completed!");
        navigate("/auth/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="app">
      <main>
        <section className="section-login">
          <div className="section-main">
            <div className="section-login-1">
              <div className="section-login-1-main">
                <div className="section-login-1-img">
                  <img src={coverImage} alt="Hair salon" />
                </div>
              </div>
            </div>
            <div className="section-login-2">
              <div className="section-login-2-main">
                <h1 className="section-login-2-title">Signup</h1>
                <form className="section-login-2-form" onSubmit={signUpHandler}>
                  <div className="login-form-1">
                    <label htmlFor="input-email">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      placeholder="john@example.com"
                      value={enteredEmail}
                      onChange={emailInputChangeHandler}
                      onBlur={emailInputBlurHandler}
                    />
                    {emailInputIsInvalid && (
                      <p className="error-text">
                        Please enter valid email address!
                      </p>
                    )}
                  </div>
                  <div className="login-form-2">
                    <label htmlFor="input-name">Name</label>
                    <input
                      type="text"
                      id="input-name"
                      placeholder="John Doe"
                      value={enteredName}
                      onChange={nameInputChangeHandler}
                      onBlur={nameInputBlurHandler}
                    />
                    {nameInputIsInvalid && (
                      <p className="error-text">Please enter valid name!</p>
                    )}
                  </div>
                  <div className="login-form-3">
                    <label htmlFor="input-password">Password</label>
                    <input
                      type="password"
                      id="input-password"
                      placeholder="At least 8 characters"
                      value={enteredPassword}
                      onChange={passwordInputChangeHandler}
                      onBlur={passwordInputBlurHandler}
                    />
                    {passwordInputIsInvalid && (
                      <p className="error-text">Please enter password!</p>
                    )}
                  </div>
                  <div className="login-form-4">
                    <input type="checkbox" id="input-checkbox" />
                    <p>
                      By creating an account, you agree to the{" "}
                      <a href="#">Terms & Conditions.</a>
                    </p>
                  </div>
                  <div className="login-form-submit-btn">
                    <button type="submit">Create an Account</button>
                  </div>
                  <div className="login-form-5">
                    <p>
                      Already have an account?{" "}
                      <Link to="/auth/login">Sign In</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <div className="container">
        <header>
          <div className={"header-headings"}>
            <span>
              {!isOtpPage
                ? "Create new account"
                : "Enter your verification code"}
            </span>
          </div>
        </header>

        <form className="account-form" onSubmit={signUpHandler}>
          {
            !isOtpPage ? (
              <div className={"account-form-fields"}>
                <input
                  id="name"
                  name="name"
                  type="name"
                  placeholder="Name"
                  value={enteredName}
                  onChange={nameInputChangeHandler}
                  onBlur={nameInputBlurHandler}
                />
                {nameInputIsInvalid && (
                  <p className="error-text">Please enter valid name address!</p>
                )}
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  value={enteredEmail}
                  onChange={emailInputChangeHandler}
                  onBlur={emailInputBlurHandler}
                />
                {emailInputIsInvalid && (
                  <p className="error-text">
                    Please enter valid email address!
                  </p>
                )}
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={enteredPassword}
                  onChange={passwordInputChangeHandler}
                  onBlur={passwordInputBlurHandler}
                />
                {passwordInputIsInvalid && (
                  <p className="error-text">
                    Please enter atleast 8 character!
                  </p>
                )}
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  value={enteredConfirmPassword}
                  onChange={confirmPasswordInputChangeHandler}
                  onBlur={confirmPasswordInputBlurHandler}
                />
                {confirmPasswordInputIsInvalid && (
                  <p className="error-text">Password should be match!</p>
                )}
              </div>
            ) : null
            // <OtpInput
            //   className="otpInput"
            //   value={otp}
            //   onChange={handleChange}
            //   numInputs={6}
            //   isInputNum
            // />
          }
          <button className="btn-submit-form" type="submit">
            {!isOtpPage ? "Sign Up" : "Verify OTP"}
          </button>
        </form>
        <div className="btn-submit-form" style={{ marginTop: "100px" }}>
          <Link to="/login">Already have an account</Link>
        </div>
      </div> */}
    </div>
  );
}

export default SignUp;
