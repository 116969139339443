import { Container } from "@mui/material";
import React, { useState } from "react";
import EditCalendarIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import "./IndividualShop.css";
import AddressAndTimings from "../AddressAndTimings/AddressAndTimings";
import BackDrop from "../BackDrop/BackDrop";
import "../Booking/Booking.css";
import { Outlet } from "react-router-dom";
import SidebarLinks from "../SidebarLinks/SidebarLinks";

const sideBarLinksData = [
  {
    id: 1,
    name: "Staff",
    link: "/business/staff",
    icon: <PersonIcon />,
    active: true,
  },
  {
    id: 2,
    name: "Services",
    link: "/business/services",
    icon: <EditCalendarIcon />,
    active: false,
  },
  {
    id: 3,
    name: "Classes",
    link: "/business/classes",
    icon: <GroupsIcon />,
    active: false,
  },
];

function IndividualShop({ shopData }) {
  const [sideBarLinks, setSideBarLinks] = useState(sideBarLinksData);
  const sidebarHandler = (id) => {
    setSideBarLinks((prev) => {
      return prev.map((s) => {
        if (s.id === id) {
          return { ...s, active: true };
        } else {
          return { ...s, active: false };
        }
      });
    });
  };

  if (!shopData) {
    return <BackDrop loading={true} />;
  }

  return (
    <Container maxWidth="xl">
      <div className="main-container staff-section">
        <div className="sidebar">
          <h2 className="company-name">{shopData.companyDetails.name}</h2>
          <SidebarLinks
            sideBarLinks={sideBarLinks}
            sidebarHandler={sidebarHandler}
          />
        </div>
        <Outlet context={{ shopData }} />

        <div className="working-hours">
          <AddressAndTimings
            shopData={shopData.companyDetails}
            showContent={false}
          />
        </div>
      </div>
    </Container>
  );
}

export default IndividualShop;
