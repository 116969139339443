import React from "react";
import BackDrop from "../components/BackDrop/BackDrop";
import IndividualShop from "../components/IndividualShop/IndividualShop";
import { useOutletContext } from "react-router-dom";

function NonShopLayout() {
  const { shopData, subdomain } = useOutletContext();

  if (!shopData || !subdomain) {
    return <BackDrop loading={true} />;
  }
  return (
    <>
      {!shopData.companyDetails.salon && (
        <IndividualShop subdomain={subdomain} shopData={shopData} />
      )}
    </>
  );
}

export default NonShopLayout;
