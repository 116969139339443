import React, { useEffect, useState } from "react";
import StaffList from "../components/StaffList/StaffList";
import IndividualSearviceList from "../components/IndividualSearviceList/IndividualSearviceList";
import IndividualSlotSelection from "../components/IndividualSlotSelection/IndividualSlotSelection";
import DetailsOfService from "../components/DetailsOfService/DetailsOfService";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import axiosInstance from "../utils/AxiosMainHelper";
import axiosStaticInstance from "../utils/AxiosHelper";
import BookingForm from "../components/BookingForm/BookingForm";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import dayjs from "dayjs";
import useInput from "../hooks/useInput";

const selectedBoxCss = {
  backgroundColor: "rgb(3, 122, 255)",
  borderColor: "rgb(3, 122, 255)",
  color: "white",
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function SingleStaffWrapper() {
  const navigate = useNavigate();
  const params = useParams();

  const { shopData } = useOutletContext();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [mainLayout, setMainLayout] = useState(0);
  const [servicesData, setServicesData] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [slotsData, setSlotsData] = useState([]);
  const [dateValue, setDateValue] = useState(dayjs());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (shopData && params.staffId) {
      const filteredShopData = shopData.staffDetailsList.filter(
        (s) => s.username.toLowerCase() === params.staffId
      );

      if (filteredShopData.length > 0) {
        setSelectedStaff(filteredShopData[0]);
      } else {
        navigate("/business");
      }
    }
  }, [shopData]);

  useEffect(() => {
    if (
      selectedStaff &&
      selectedStaff.resourceDtoList &&
      selectedStaff.resourceDtoList.length > 0
    ) {
      setServicesData(
        selectedStaff.resourceDtoList.map((s) => {
          return { ...s, isSelected: false };
        })
      );
    }
  }, [selectedStaff]);

  useEffect(() => {
    const selected = [];
    let total = 0;
    servicesData.forEach((l) => {
      if (l.isSelected) {
        selected.push(l);
        total = total + parseInt(l.amount);
      }
    });

    setSelectedServices(selected);
  }, [servicesData]);

  useEffect(() => {
    if (selectedServices && mainLayout === 1) {
      const fetchShopBySubDomain = async () => {
        try {
          const response = await axiosInstance.post(`booking/non-salon/slots`, {
            companyId: shopData.companyDetails.id,
            servicesList: selectedServices.map((s) => s.id),
            day: dayjs().format("MMM/DD/YYYY"),
            userId: selectedStaff.staffId,
          });

          if (response?.status === 200) {
            setSlotsData(response.data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchShopBySubDomain();
    }
  }, [selectedServices, mainLayout, selectedStaff, shopData]);

  const serviceSelectionHandler = (id) => {
    setServicesData((prev) =>
      prev.map((l) => {
        if (l.id === id) {
          return { ...l, isSelected: !l.isSelected };
        }
        return l;
      })
    );
  };

  const onDateChange = (date) => {
    setDateValue(date);
  };

  let {
    enteredInput: enteredName,
    inputInvalid: nameInputIsInvalid,
    blurHandler: nameInputBlurHandler,
    changeHandler: nameInputChangeHandler,
  } = useInput("", (value) => value !== "");
  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput(
    "",
    (value) =>
      value !== "" &&
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
  );
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  const [enteredPhoneNumberBlur, setEnteredPhoneNumberInputBlur] =
    useState(false);

  const validate = (value) => {
    return value !== "" && value.length >= 10 && value.length <= 15;
  };
  const enteredInputIsValid =
    enteredPhoneNumberBlur && validate(enteredPhoneNumber);
  const phoneNumberInputIsInvalid =
    !enteredInputIsValid && enteredPhoneNumberBlur;

  const phoneNumberInputChangeHandler = (phone) => {
    setEnteredPhoneNumber(phone);
  };

  const phoneNumberInputBlurHandler = () => {
    setEnteredPhoneNumberInputBlur(true);
  };

  const nameProp = {
    enteredName,
    nameInputBlurHandler,
    nameInputChangeHandler,
    nameInputIsInvalid,
  };
  const emailProp = {
    enteredEmail,
    emailInputBlurHandler,
    emailInputChangeHandler,
    emailInputIsInvalid,
  };
  const phoneNumberProp = {
    enteredPhoneNumber,
    phoneNumberInputBlurHandler,
    phoneNumberInputChangeHandler,
    phoneNumberInputIsInvalid,
  };

  const confirmBookingHandler = async (e) => {
    e.preventDefault();
    nameInputBlurHandler();
    emailInputBlurHandler();
    phoneNumberInputBlurHandler();

    if (!enteredName || !enteredEmail || !enteredPhoneNumber) {
      return;
    }
    setLoading(true);

    const payload = {
      staffId: selectedStaff.staffId,
      serviceId: selectedServices[0].id,
      companyId: shopData.companyDetails.id,
      serviceList: selectedServices.map((s) => s.id),
      customerDto: {
        name: enteredName,
        phone: enteredPhoneNumber,
        email: enteredEmail,
      },
      day: dayjs(dateValue).format("MMM/DD/YYYY"),
      timeSlot: selectedSlot,
    };
    try {
      const response = await axiosInstance.post(`external/booking`, payload);
      if (response.data === "succes") {
        setLoading(false);
        navigate("/booking-success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main ">
      {mainLayout === 0 && (
        <IndividualSearviceList
          currency={shopData.companyDetails.currency}
          selectedStaff={selectedStaff}
          setMainLayout={setMainLayout}
          stringAvatar={stringAvatar}
          servicesData={servicesData}
          serviceSelectionHandler={serviceSelectionHandler}
          selectedBoxCss={selectedBoxCss}
          selectedServices={selectedServices}
          showBackBtn={false}
        />
      )}

      {mainLayout === 1 && (
        <IndividualSlotSelection
          selectedStaff={selectedStaff}
          setMainLayout={setMainLayout}
          selectedServices={selectedServices}
          dateValue={dateValue}
          selectedSlot={selectedSlot}
          onDateChange={onDateChange}
          setSlotsData={setSlotsData}
          shopData={shopData}
          slotsData={slotsData}
          setSelectedSlot={setSelectedSlot}
          slotApiURL={`booking/non-salon/slots`}
        />
      )}

      {mainLayout === 2 && (
        <>
          <div className="topbar" key={selectedStaff.id}>
            <ChevronLeftIcon
              onClick={() => setMainLayout((prev) => prev - 1)}
              sx={{ cursor: "pointer" }}
            />
            <h2>Enter your details</h2>
          </div>

          <DetailsOfService
            selectedServices={selectedServices}
            selectedStaff={selectedStaff}
            selectedSlot={selectedSlot}
            dateValue={dateValue}
          />
          <BookingForm
            {...nameProp}
            {...emailProp}
            {...phoneNumberProp}
            showHeader={false}
          />

          {selectedServices && (
            <div className="confirmSelection">
              <LoadingButton
                onClick={confirmBookingHandler}
                variant="contained"
                disabled={loading}
                loading={loading}
                loadingIndicator="Booking..."
              >
                Confirm Booking
              </LoadingButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SingleStaffWrapper;
