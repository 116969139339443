import { Avatar, Button } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function IndividualSearviceList({
  currency,
  selectedStaff,
  setMainLayout,
  stringAvatar,
  servicesData,
  serviceSelectionHandler,
  selectedBoxCss,
  selectedServices,
  showBackBtn = true,
}) {
  return (
    <>
      {selectedStaff && (
        <div className="topbar" key={selectedStaff.id}>
          {showBackBtn && (
            <ChevronLeftIcon
              onClick={() => setMainLayout((prev) => prev - 1)}
              sx={{ cursor: "pointer" }}
            />
          )}
          <Avatar {...stringAvatar(selectedStaff.name)} />
          <h2>{selectedStaff.name}</h2>
        </div>
      )}
      <div className="services-list">
        <h3 className="serviceListHeader">Services List</h3>
        <div className="servicesWrapper">
          {servicesData.length > 0 &&
            servicesData.map((single) => {
              return (
                <div
                  key={single.id}
                  className="single-service-container"
                  onClick={() => serviceSelectionHandler(single.id)}
                >
                  <div className="service-details">
                    <div
                      className="selection-box"
                      style={single.isSelected ? selectedBoxCss : {}}
                    >
                      <CheckIcon fontSize="large" />
                    </div>
                    <div className="details">
                      <h3>{single.name}</h3>
                      <p>{single.duration} mins</p>
                    </div>
                  </div>
                  <div className="service-price">
                    {single.amount === "0.00" ? (
                      <span className="freeAmount">Free</span>
                    ) : (
                      `${currency}${single.amount}`
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        {selectedServices.length > 0 && (
          <div className="confirmSelection">
            <Button
              variant="contained"
              onClick={() => setMainLayout((prev) => prev + 1)}
            >
              Confirm Service Selection
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default IndividualSearviceList;
