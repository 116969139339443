import React, { useEffect, useState } from "react";
import StaffList from "../components/StaffList/StaffList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axiosInstance from "../utils/AxiosMainHelper";
import axiosInstanceStatic from "../utils/AxiosHelper";
import BookingForm from "../components/BookingForm/BookingForm";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import useInput from "../hooks/useInput";
import { Avatar, Button } from "@mui/material";
import "./ServicesWrapper.css";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function ClassesWrapper() {
  const navigate = useNavigate();

  const { shopData } = useOutletContext();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [mainLayout, setMainLayout] = useState(0);
  const [dateValue, setDateValue] = useState(dayjs());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slotList, setSlotList] = useState([]);
  const [acceptedDates, setAcceptedDates] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [tz, setTz] = useState(shopData.companyDetails.timeZone.value);

  useEffect(() => {
    if (mainLayout === 2 && selectedStaff) {
      setAcceptedDates(
        selectedClass.sessions.map((s) => {
          const dateParts = s.day.split("/");
          const year = dateParts[2];
          const month = (
            "0" +
            (new Date(Date.parse(s.day)).getMonth() + 1)
          ).slice(-2);
          const day = ("0" + dateParts[1]).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        })
      );
      setSlotList(selectedClass.sessions[0]);
      setDateValue(dayjs(selectedClass.sessions[0].day));
      setSelectedSlot(selectedClass.sessions[0].time);
    }
  }, [mainLayout, selectedStaff]);

  const onDateChange = (date) => {
    setDateValue(date);
    const selectedSession = selectedClass.sessions.find((s) => {
      return dayjs(date).format("MMM/DD/YYYY") === s.day;
    });
    setSlotList(selectedSession);
    setDateValue(dayjs(selectedSession.day));
    setSelectedSlot(selectedSession.time);
  };

  let {
    enteredInput: enteredName,
    inputInvalid: nameInputIsInvalid,
    blurHandler: nameInputBlurHandler,
    changeHandler: nameInputChangeHandler,
  } = useInput("", (value) => value !== "");
  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput(
    "",
    (value) =>
      value !== "" &&
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
  );
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  const [enteredPhoneNumberBlur, setEnteredPhoneNumberInputBlur] =
    useState(false);

  const validate = (value) => {
    return value !== "" && value.length >= 10 && value.length <= 15;
  };
  const enteredInputIsValid =
    enteredPhoneNumberBlur && validate(enteredPhoneNumber);
  const phoneNumberInputIsInvalid =
    !enteredInputIsValid && enteredPhoneNumberBlur;

  const phoneNumberInputChangeHandler = (phone) => {
    setEnteredPhoneNumber(phone);
  };

  const phoneNumberInputBlurHandler = () => {
    setEnteredPhoneNumberInputBlur(true);
  };

  const nameProp = {
    enteredName,
    nameInputBlurHandler,
    nameInputChangeHandler,
    nameInputIsInvalid,
  };
  const emailProp = {
    enteredEmail,
    emailInputBlurHandler,
    emailInputChangeHandler,
    emailInputIsInvalid,
  };
  const phoneNumberProp = {
    enteredPhoneNumber,
    phoneNumberInputBlurHandler,
    phoneNumberInputChangeHandler,
    phoneNumberInputIsInvalid,
  };

  const confirmBookingHandler = async (e) => {
    e.preventDefault();
    nameInputBlurHandler();
    emailInputBlurHandler();
    phoneNumberInputBlurHandler();

    if (!enteredName || !enteredEmail || !enteredPhoneNumber) {
      return;
    }
    setLoading(true);

    const payload = {
      staffId: selectedStaff.staffId,
      classId: selectedClass.classId,
      companyId: shopData.companyDetails.id,
      customerDto: {
        name: enteredName,
        phone: enteredPhoneNumber,
        email: enteredEmail,
      },
    };
    try {
      const response = await axiosInstanceStatic.post(`class-booking`, payload);
      if (response.data === "success") {
        setLoading(false);
        navigate("/booking-success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main classes">
      {mainLayout === 0 && (
        <>
          <div className="mainWrapper">
            <p style={{ marginBottom: "10px" }}>Your class</p>
            {shopData?.classes?.length > 0 &&
              shopData?.classes?.map((cls) => {
                return (
                  <div
                    className="staff-wrapper"
                    key={cls.classId}
                    onClick={() => {
                      setSelectedClass(cls);
                      setMainLayout((prev) => prev + 1);
                    }}
                  >
                    <div className="details">
                      <Avatar {...stringAvatar(cls.name)} />
                      <p>{cls.name}</p>
                    </div>
                    <ChevronRightIcon />
                  </div>
                );
              })}
          </div>
        </>
      )}

      {mainLayout === 1 && (
        <>
          <div className="topbar">
            <ChevronLeftIcon
              onClick={() => setMainLayout((prev) => prev - 1)}
              sx={{ cursor: "pointer" }}
            />
            <h2>Select Staff</h2>
          </div>
          <StaffList
            staffList={selectedClass?.staffs}
            setSelectedStaff={setSelectedStaff}
            setMainLayout={setMainLayout}
            stringAvatar={stringAvatar}
            showHeader={false}
          />
        </>
      )}

      {mainLayout === 2 && selectedStaff && (
        <>
          <div className="topbar">
            <ChevronLeftIcon
              onClick={() => setMainLayout((prev) => prev - 1)}
              sx={{ cursor: "pointer" }}
            />
            <h2>Select Date and Time</h2>
          </div>
          <div className="detailsOfService">
            {selectedClass && (
              <h4 key={selectedClass.name}>{selectedClass.name} Class</h4>
            )}
            <p>
              with {selectedStaff.name}{" "}
              {dateValue
                ? `on ${dayjs(dateValue).format("MMM / D / YYYY")}`
                : ""}{" "}
              {selectedSlot ? `at ${selectedSlot}` : ""}
            </p>
          </div>
          <div className="booking-wrapper">
            <div className="dateAndSlots">
              <div className="wrapperDate">
                <h2>Pick your date</h2>
                <div className="timezone-wrapper">
                  <TimezoneSelect
                    isDisabled={true}
                    value={tz}
                    onChange={setTz}
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    minDate={dayjs()}
                    className="datePicker"
                    value={dateValue}
                    shouldDisableDate={(day) => {
                      return !acceptedDates.includes(
                        dayjs(day).format("YYYY-MM-DD")
                      );
                    }}
                    onChange={(newValue) => {
                      onDateChange(newValue);
                      // fetchShopBookingData(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
              {slotList && slotList.time && (
                <div className="wrapper">
                  <div className="slot-title-part">
                    <h2>Available slots</h2>
                  </div>

                  <div className="slotsWrapper">
                    {slotList.time && (
                      <Button
                        variant="contained"
                        className="slotBtn"
                        size="medium"
                        sx={{
                          border: `1px solid ${
                            slotList.time !== selectedSlot
                              ? "#1976d2"
                              : "transparent"
                          }`,
                          background:
                            slotList.time === selectedSlot
                              ? "primary"
                              : "transparent",
                          color:
                            slotList.time === selectedSlot ? "#fff" : "#000",
                        }}
                      >
                        {slotList.time}
                      </Button>
                    )}
                  </div>
                  <div className="wrapper2">
                    <div className="durationWrapper">
                      <AccessTimeIcon sx={{ marginRight: "8px" }} />{" "}
                      {slotList.durationInMins} mins
                    </div>
                    <p>Available slots: {slotList.availableSlots}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedSlot && (
            <div className="confirmSelection">
              <Button
                variant="contained"
                onClick={() => setMainLayout((prev) => prev + 1)}
              >
                Confirm Slot Selection
              </Button>
            </div>
          )}
        </>
      )}

      {mainLayout === 3 && (
        <>
          <div className="topbar" key={selectedStaff.id}>
            <ChevronLeftIcon
              onClick={() => setMainLayout((prev) => prev - 1)}
              sx={{ cursor: "pointer" }}
            />
            <h2>Enter your details</h2>
          </div>

          <div className="detailsOfService">
            {selectedClass && (
              <h4 key={selectedClass.name}>{selectedClass.name} Class</h4>
            )}
            <p>
              with {selectedStaff.name}{" "}
              {dateValue
                ? `on ${dayjs(dateValue).format("MMM / D / YYYY")}`
                : ""}{" "}
              {selectedSlot ? `at ${selectedSlot}` : ""}
            </p>
          </div>
          <BookingForm
            {...nameProp}
            {...emailProp}
            {...phoneNumberProp}
            showHeader={false}
          />

          <div className="confirmSelection">
            <LoadingButton
              onClick={confirmBookingHandler}
              variant="contained"
              disabled={loading}
              loading={loading}
              loadingIndicator="Booking..."
            >
              Confirm Booking
            </LoadingButton>
          </div>
        </>
      )}
    </div>
  );
}

export default ClassesWrapper;
