import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import Shop from "../components/Shop/Shop";
import { useOutletContext } from "react-router-dom";

function ShopLayout() {
  const { shopData, subdomain } = useOutletContext();

  return (
    <>
      {shopData.companyDetails.salon && (
        <>
          <ResponsiveAppBar subdomain={subdomain} shopData={shopData} />
          <Shop subdomain={subdomain} shopData={shopData} />
        </>
      )}
    </>
  );
}

export default ShopLayout;
