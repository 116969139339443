import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import Home from "../components/Home";

function HomeLayout() {
  return (
    <>
      <ResponsiveAppBar
        shopData={{ companyDetails: { name: "BMA" } }}
        showShopLogo={false}
      />
      <Home />
    </>
  );
}

export default HomeLayout;
