import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosMainHelper";
import coverImage from "../assets/login-main.jpg";
import "./Auth.css";
import { AuthContext } from "../context/AuthContext";
import GoogleLogin from "react-google-login";

function Login() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.isAuthenticated) {
      navigate("/home");
    }
  }, []);

  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput("", (value) => value !== "");

  let {
    enteredInput: enteredPassword,
    inputInvalid: passwordInputIsInvalid,
    blurHandler: passwordInputBlurHandler,
    changeHandler: passwordInputChangeHandler,
  } = useInput("", (value) => value !== "");

  const loginHandler = async (e) => {
    e.preventDefault();

    emailInputBlurHandler();
    passwordInputBlurHandler();

    if (!enteredEmail || !enteredPassword) {
      return;
    }

    try {
      const response = await axiosInstance.post("auth/signin", {
        email: enteredEmail,
        password: enteredPassword,
      });
      if (response.status === 200) {
        authCtx.login(response.data);
        toast.success("Yay, You're Logged In");
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data));
        navigate("/home");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const responseGoogle = (response) => {
    console.log({ response });
  };

  return (
    <div id="app">
      <main className="login">
        <section className="section-login">
          <div className="section-main">
            <div className="section-login-1">
              <div className="section-login-1-main">
                <div className="section-login-1-img">
                  <img src={coverImage} alt="Hair salon" />
                </div>
              </div>
            </div>
            <div className="section-login-2">
              <div className="section-login-2-main">
                <h1 className="section-login-2-title">Log In</h1>
                <form className="section-login-2-form" onSubmit={loginHandler}>
                  <div className="login-form-1">
                    <label htmlFor="input-email">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      placeholder="john@example.com"
                      value={enteredEmail}
                      onChange={emailInputChangeHandler}
                      onBlur={emailInputBlurHandler}
                    />
                    {emailInputIsInvalid && (
                      <p className="error-text">
                        Please enter valid email address!
                      </p>
                    )}
                  </div>
                  <div className="login-form-3">
                    <label htmlFor="input-password">Password</label>
                    <input
                      type="password"
                      id="input-password"
                      placeholder="At least 8 characters"
                      value={enteredPassword}
                      onChange={passwordInputChangeHandler}
                      onBlur={passwordInputBlurHandler}
                    />
                    {passwordInputIsInvalid && (
                      <p className="error-text">Please enter password!</p>
                    )}
                  </div>
                  <div className="login-form-submit-btn">
                    <button type="submit">Log In</button>
                  </div>
                  <div className="login-form-5">
                    <p style={{ textAlign: "end" }}>
                      <Link to={"/auth/forgot-password"}>Forgot password</Link>
                    </p>
                  </div>
                  <div className="login-form-5">
                    <p>
                      Didn't have an account?{" "}
                      <Link to="/auth/signup">Sign Up</Link>
                    </p>
                  </div>

                  {/* <div className="googleLoginBtnWrapper"> */}
                  <GoogleLogin
                    className="googleLoginBtnWrapper"
                    clientId="536709672021-dr3uch132daipeb6smk29arofpvnmkr3.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  {/* </div> */}
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Login;
