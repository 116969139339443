import React, {useState} from "react";
import DateCalenderPicker from "../DateCalenderPicker/DateCalenderPicker";
import {Button} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DetailsOfService from "../DetailsOfService/DetailsOfService";
import TimezoneSelect, {allTimezones} from "react-timezone-select";
import axiosMainInstance from "../../utils/AxiosMainHelper";
import dayjs from "dayjs";

function IndividualSlotSelection({
                                     selectedStaff,
                                     setMainLayout,
                                     selectedServices,
                                     dateValue,
                                     selectedSlot,
                                     onDateChange,
                                     setSlotsData,
                                     shopData,
                                     slotsData,
                                     setSelectedSlot,
                                     slotApiURL,
                                 }) {

    const [timezone, setTimeZone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone);


    const getAvailableSlotsByTimeZone = async (timeZone) => {
        setTimeZone(timeZone)
        console.log(timezone.value)
        let apiPayload = {
            companyId: shopData.companyDetails.id,
            servicesList: selectedServices.map((s) => s.id),
            userId: selectedStaff.staffId,
            timeZone: timeZone.value
        }
        try {
            const response = await axiosMainInstance.post(slotApiURL, {
                ...apiPayload,
                day: dayjs(dateValue).format("MMM/DD/YYYY"),
            });
            if (response.status === 200) {
                setSlotsData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="topbar" key={selectedStaff.id}>
                <ChevronLeftIcon
                    onClick={() => setMainLayout((prev) => prev - 1)}
                    sx={{cursor: "pointer"}}
                />
                <h2>Select Date and Time</h2>
            </div>
            <DetailsOfService
                selectedServices={selectedServices}
                selectedStaff={selectedStaff}
                selectedSlot={selectedSlot}
                dateValue={dateValue}
            />
            <div className="booking-wrapper">
                <div className="dateAndSlots">
                    <div className="wrapperDate">
                        <h2>Pick your date</h2>
                        <div className="timezone-wrapper">
                            <TimezoneSelect
                                // isDisabled={true}
                                value={timezone}
                                onChange={getAvailableSlotsByTimeZone}
                                timezones={{
                                    ...allTimezones,
                                    "America/Lima": "Pittsburgh",
                                    "Europe/Berlin": "Frankfurt",
                                }}
                            />
                        </div>
                        <DateCalenderPicker
                            dateValue={dateValue}
                            onDateChange={onDateChange}
                            setShopBookingData={setSlotsData}
                            timeZone={timezone.value}
                            apiPayload={{
                                companyId: shopData.companyDetails.id,
                                servicesList: selectedServices.map((s) => s.id),
                                userId: selectedStaff.staffId
                            }}
                            URL={slotApiURL}
                        />
                    </div>
                    {slotsData?.availableSlots?.length === 0 && (
                        <div className="wrapper">
                            <div className="slot-title-part">
                                <h2>No Available slots for selected date!</h2>
                            </div>
                        </div>
                    )}
                    {slotsData?.availableSlots?.length > 0 && (
                        <div className="wrapper">
                            <div className="slot-title-part">
                                <h2>Available slots</h2>
                                <div className="slot-part-color-box">
                                    <h5>
                                        <span></span>Available
                                    </h5>
                                    <h5>
                                        <span></span>Booked
                                    </h5>
                                </div>
                            </div>
                            <div className="slotsWrapper">
                                {slotsData?.availableSlots?.map((slot) => {
                                    return (
                                        <Button
                                            key={slot}
                                            variant="contained"
                                            className="slotBtn"
                                            size="medium"
                                            // color={slot === selectedSlot ? "primary" : "#fff"}
                                            sx={{
                                                border: `1px solid ${
                                                    slot !== selectedSlot ? "#1976d2" : "transparent"
                                                }`,
                                                background:
                                                    slot === selectedSlot ? "primary" : "transparent",
                                                color: slot === selectedSlot ? "#fff" : "#000",
                                            }}
                                            onClick={() => {
                                                setSelectedSlot((prev) =>
                                                    prev === slot ? null : slot
                                                );
                                            }}
                                        >
                                            {slot}
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {selectedSlot && (
                <div className="confirmSelection">
                    <Button
                        variant="contained"
                        onClick={() => setMainLayout((prev) => prev + 1)}
                    >
                        Confirm Slot Selection
                    </Button>
                </div>
            )}
        </>
    );
}

export default IndividualSlotSelection;
