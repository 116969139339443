import dayjs from "dayjs";
import React from "react";

function DetailsOfService({
  selectedServices,
  selectedStaff,
  selectedSlot,
  dateValue,
}) {
  return (
    <div className="detailsOfService">
      {selectedServices.length > 0 &&
        selectedServices.map((s) => {
          return (
            <h4 key={s.name}>
              {s.duration} Minutes {s.name}
            </h4>
          );
        })}
      <p>
        with {selectedStaff.name}{" "}
        {dateValue ? `on ${dayjs(dateValue).format("MMM / D / YYYY")}` : ""}{" "}
        {selectedSlot ? `at ${selectedSlot}` : ""}
      </p>
    </div>
  );
}

export default DetailsOfService;
