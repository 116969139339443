import React, { useContext, useEffect, useState } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import { SubDomainContext } from "../context/SubDomainContext";
import axiosMainInstance from "../utils/AxiosMainHelper";
import BookingSuccess from "../components/BookingSuccess/BookingSuccess";

function BookingSuccessLayout() {
  const { subdomain } = useContext(SubDomainContext);
  const [shopData, setShopData] = useState(null);
  const fetchShopBySubDomain = async () => {
    try {
      const response = await axiosMainInstance.get(
        `external/v2/company?name=${subdomain}`
      );
      if (response?.status === 200) {
        setShopData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchShopBySubDomain();
  }, []);
  return (
    <>
      {shopData && (
        <ResponsiveAppBar subdomain={subdomain} shopData={shopData} />
      )}
      <BookingSuccess />
    </>
  );
}

export default BookingSuccessLayout;
