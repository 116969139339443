import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselContainer.css";

function CarouselContainer({ images = [] }) {
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false}>
      {images.map((image) => {
        return (
          <div className="slider-image" key={image}>
            <img alt="" src={image} width={"100%"} />
          </div>
        );
      })}
    </Carousel>
  );
}

export default CarouselContainer;
