import React from "react";
import CheckIcon from "@mui/icons-material/Check";

const selectedBoxCss = {
  backgroundColor: "rgb(3, 122, 255)",
  borderColor: "rgb(3, 122, 255)",
  color: "white",
};

function ServicesList({ service, serviceSelectionHandler,currency }) {
  return (
    <>
      {service.servicesList.length === 0 && <h2>No services found!</h2>}
      {service.servicesList.length > 0 &&
        service.servicesList.map((single) => {
          return (
            <div
              key={single.id}
              className="single-service-container"
              onClick={() =>
                serviceSelectionHandler(single.id, service.groupId)
              }
            >
              <div className="service-details">
                <div
                  className="selection-box"
                  style={single.isSelected ? selectedBoxCss : {}}
                >
                  <CheckIcon fontSize="large" />
                </div>
                <div className="details">
                  <h3>{single.name}</h3>
                  <p>{single.duration} mins</p>
                </div>
              </div>
              <div className="service-price">
                {single.amount === "0.00" ? (
                  <span className="freeAmount">Free</span>
                ) : (
                    `${currency}${single.amount}`
                )}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default ServicesList;
