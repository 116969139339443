import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SubDomainContext } from "../context/SubDomainContext";
import axiosInstance from "../utils/AxiosMainHelper";
import BackDrop from "../components/BackDrop/BackDrop";

function MainLayout() {
  const navigate = useNavigate();
  const { subdomain, mainUrl } = useContext(SubDomainContext);

  const [shopData, setShopData] = useState(null);

  const fetchShopBySubDomain = async () => {
    try {
      const response = await axiosInstance.get(
        `external/v2/company?name=${subdomain}`
      );
      if (response?.status === 200) {
        setShopData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (shopData && shopData.companyDetails && shopData.companyDetails.salon) {
      navigate("/shop");
    } else if (
      shopData &&
      shopData.companyDetails &&
      !shopData.companyDetails.salon
    ) {
      navigate(mainUrl.includes("business") ? mainUrl : "/business/staff");
    }
  }, [shopData, mainUrl]);

  useEffect(() => {
    fetchShopBySubDomain();
  }, []);

  if (!shopData || !subdomain) {
    return <BackDrop loading={true} />;
  }

  return <Outlet context={{ shopData, subdomain }} />;
}

export default MainLayout;
