import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AdbIcon from "@mui/icons-material/Adb";
import {Button, Menu, MenuItem} from "@mui/material";
import {SubDomainContextProvider} from "../../context/SubDomainContext";
import {useNavigate} from "react-router";
import {AuthContext} from "../../context/AuthContext";
import {Link} from "react-router-dom";
import logoImg from "../../assets/glam.png";


function ResponsiveAppBar({shopData, showShopLogo = true}) {
    const navigate = useNavigate();
    const subDomCtx = React.useContext(SubDomainContextProvider);
    const authCtx = React.useContext(AuthContext);

    return (
        <>
            <AppBar position="static" style={{ background: '#000000' }}>
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{display: "flex", justifyContent: "space-between"}}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Link to={"https://glamjot.com"}>
                                {/*<img*/}
                                {/*    src={*/}
                                {/*        "https://cdn.mcauto-images-production.sendgrid.net/5238f88b0a6059da/50f9bed9-ee9a-4b1e-8366-febff1a00366/446x160.png"*/}
                                {/*    }*/}
                                {/*    alt="Shop"*/}
                                {/*    height={50}*/}
                                {/*/>*/}
                                {/*<Button variant="contained" style={{ fontFamily: 'BlinkMacSystemFont', textTransform: 'none' }}*/}
                                {/*>*/}
                                {/*    BookMyAgenda*/}

                                {/*</Button >*/}
                                <img
                                    alt="..."
                                    src={logoImg} width={200} height={40}
                                />
                            </Link>
                        </div>

                        {/*<div className="btnWrapperHome">*/}
                            {!subDomCtx?.subdomain && (
                                <Box sx={{flexGrow: 0}}>
                                    <Link to={"https://bookmyagenda.com"}>
                                        <Button variant="contained"
                                        >
                                            For Business

                                        </Button>
                                    </Link>
                                </Box>
                            )}
                        {/*    {authCtx.isAuthenticated ? (*/}
                        {/*        <Button*/}
                        {/*            variant="contained"*/}
                        {/*            className="loginBtn"*/}
                        {/*            onClick={() => navigate("/admin/bookings")}*/}
                        {/*        >*/}
                        {/*            My Account*/}
                        {/*        </Button>*/}
                        {/*    ) : (<>*/}
                        {/*        <Link to={"https://bookmyagenda.com"}>*/}
                        {/*            <img*/}
                        {/*                src={*/}
                        {/*                    "https://cdn.mcauto-images-production.sendgrid.net/5238f88b0a6059da/50f9bed9-ee9a-4b1e-8366-febff1a00366/446x160.png"*/}
                        {/*                }*/}
                        {/*                alt="Shop"*/}
                        {/*                height={50}*/}
                        {/*            />*/}
                        {/*            <Button variant="contained" style={{ fontFamily: 'BlinkMacSystemFont', textTransform: 'none' }}*/}
                        {/*            >*/}
                        {/*                For Business*/}

                        {/*            </Button >*/}
                        {/*        </Link>*/}
                        {/*        <Button*/}
                        {/*            variant="contained"*/}
                        {/*            className="loginBtn"*/}
                        {/*            onClick={() => navigate("/auth/login")}*/}
                        {/*        >*/}
                        {/*            Login*/}
                        {/*        </Button>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </Toolbar>
                </Container>
            </AppBar>
            {showShopLogo && (
                <Container maxWidth="xl" style={{display: "flex"}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: "flex",
                            fontFamily: "cursive",
                            fontWeight: 900,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            fontSize: "24px",
                            padding: "16px 0",
                        }}
                    >
                        {shopData?.companyDetails?.name && shopData.companyDetails.name}
                    </Typography>
                </Container>
            )}
        </>
    );
}

export default ResponsiveAppBar;
