import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  InputAdornment, Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../utils/AxiosHelper";
import "./Home.css";
import PlaceIcon from "@mui/icons-material/Place";
import StoreIcon from "@mui/icons-material/Store";
import { useNavigate } from "react-router";
import AsyncSelect from "react-select/async";
import {Link} from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [shopList, setShopList] = useState([]);
  const [searchWith, setSearchWith] = useState("location");
  const [searchValue, setSearchValue] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filteredShopList, setFilteredShopList] = useState([]);

  console.log({ searchValue });

  const fetchShopList = async () => {
    try {
      const response = await axiosInstance.get(`home/page`);

      if (response?.status === 200) {
        setShopList(response.data.shopList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchShopList();
  }, []);

  useEffect(() => {
    let filteredShops = shopList.map((s) => {
      if (searchWith === "location") {
        return { id: s.location, label: s.location };
      } else {
        return { id: s.name, label: s.name };
      }
    });

    const uniqueShops = filteredShops.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);


    setFilteredCategory(
        uniqueShops
    );
  }, [shopList, searchWith]);

  console.log({ filteredCategory });

  const searchHandler = (e) => {
    e.preventDefault();

    setFilteredShopList(
      shopList.filter((s) => {
        console.log(
          "s[searchWith] ",
          s[searchWith],
          " searchValue",
          searchValue
        );
        return s[searchWith] === searchValue.id;
      })
    );
    // fetchShopList(`home?${searchWith}=${searchValue}`);
  };

  console.log({ filteredShopList });

  const filterColors = (inputValue) => {
    return filteredCategory.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const handleInputChange = (values) => {
    console.log({ values });
    setSearchValue(values);
  };

  return (
    <>
      <div className="searchSection">
        <div className="searchBoxWrapper">
          <div className="searchBox">
            <div className="types">
              <div
                className={`icon ${searchWith === "location" ? "active" : ""}`}
                onClick={() => setSearchWith("location")}
              >
                <PlaceIcon />
                Location
              </div>
              <div
                className={`icon ${searchWith === "name" ? "active" : ""}`}
                onClick={() => setSearchWith("name")}
              >
                <StoreIcon />
                Name
              </div>
            </div>
            <div className="input">
              <AsyncSelect
                loadOptions={loadOptions}
                defaultOptions={filteredCategory}
                onChange={handleInputChange}
                value={searchValue}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="btn">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={searchHandler}
              >
                Find shops
              </Button>
            </div>
          </div>
        </div>
        <h2 style={{ color: 'whitesmoke' }} className="headerText">Book local beauty and wellness services</h2>
      </div>
      <Container maxWidth="xl">
        <div className="recommended-shops-wrapper">
          <h2>
            {filteredShopList.length > 0
              ? "Search Results"
              : "Recommended"}
          </h2>
          <div className="recommended-shops" style={{ marginBottom: '.9rem' }}>
            {filteredShopList.length > 0
              ? filteredShopList.map((shop) => {
                  return (
                    <Card
                      className="card card-main-box"
                      key={shop.name}
                      onClick={() => {
                        window.location.href =
                          window.location.protocol +
                          "//" +
                            `${shop.username}`+
                          // shop.url.split("://")[1].split(".")[0] +
                          "." +
                          window.location.host +
                          "/shop";
                        // navigate(shop.url, { replace: true });
                      }}
                    >
                      <CardActionArea>
                        <div className="card-inner-img">
                          <CardMedia
                            className="card-img-part"
                            component="img"
                            image={shop.image}
                            alt={shop.name}
                          />
                        </div>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {shop.name}
                          </Typography>
                        </CardContent>
                        <CardContent>
                          <Typography variant="p" component="div">
                            {shop.location}
                          </Typography>
                        </CardContent>
                        <CardContent>
                          {shop.tags.map((s) => {
                            return (
                              <Chip key={s} label={s} variant="outlined" />
                            );
                          })}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })
              : shopList.length > 0 &&
                shopList.map((shop) => {
                  return (
                    <Card
                      className="card card-main-box"
                      key={shop.name}
                      onClick={() => {
                        window.location.href =
                          window.location.protocol +
                          "//" +
                            `${shop.username}` +
                          // shop.url.split("://")[1].split(".")[0] +
                          "." +
                          window.location.host +
                          "/shop";
                        // navigate(shop.url, { replace: true });
                      }}
                    >
                      <CardActionArea>
                        <div className="card-inner-img">
                          <CardMedia
                            className="card-img-part"
                            component="img"
                            image={shop.image}
                            alt={shop.name}
                          />
                        </div>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {shop.name}
                          </Typography>
                        </CardContent>
                        <CardContent>
                          <Typography variant="p" component="div">
                            {shop.location}
                          </Typography>
                        </CardContent>
                        <CardContent>
                          {shop.tags.map((s) => {
                            return (
                              <Chip key={s} label={s} variant="outlined" />
                            );
                          })}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
          </div>
          <h2 style={{ marginBottom: '.9rem' }}>Own a hair & beauty Business?.
          </h2>
          <h3 style={{ marginBottom: '.9rem' }}>We'll assist you in developing your business, along with expanding your clientele, through glamjot.
          </h3>

          <Link to={"https://bookmyagenda.com"}>
            {/*<img*/}
            {/*    src={*/}
            {/*        "https://cdn.mcauto-images-production.sendgrid.net/5238f88b0a6059da/50f9bed9-ee9a-4b1e-8366-febff1a00366/446x160.png"*/}
            {/*    }*/}
            {/*    alt="Shop"*/}
            {/*    height={50}*/}
            {/*/>*/}
            {" "}
            <Button variant="contained" style={{ fontFamily: 'BlinkMacSystemFont', textTransform: 'none' }}
            >
              Partner with us

            </Button >
          </Link>
        </div>
      </Container>
    </>
  );
}

export default Home;
