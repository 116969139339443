import React from "react";
import { AccountCircle } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./BookingForm.css";

function BookingForm(props) {
  return (
    <div className="booking-form-container">
      {props.showHeader && <h2>Enter your details</h2>}
      <form className="formWrapper">
        <div className="form-control">
          <TextField
            label="Name"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={props.enteredName}
            onChange={props.nameInputChangeHandler}
            onBlur={props.nameInputBlurHandler}
            error={props.nameInputIsInvalid}
            helperText={
              props.nameInputIsInvalid ? "Please enter valid name!" : ""
            }
          />
        </div>
        <div className="form-control">
          <TextField
            label="Email"
            type="email"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={props.enteredEmail}
            onChange={props.emailInputChangeHandler}
            onBlur={props.emailInputBlurHandler}
            error={props.emailInputIsInvalid}
            helperText={
              props.emailInputIsInvalid
                ? "Please enter valid email address!"
                : ""
            }
          />
        </div>
        <div className="form-control">
          <PhoneInput
            country={"us"}
            value={props.enteredPhoneNumber}
            inputClass={"phoneInput"}
            onChange={props.phoneNumberInputChangeHandler}
            onBlur={props.phoneNumberInputBlurHandler}
            // error={props.phoneNumberInputIsInvalid}
            isValid={!props.phoneNumberInputIsInvalid}
            defaultErrorMessage={"Please enter valid phone number!"}
          />
          <p
            style={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              marginLeft: "14px",
            }}
          >
            {props.phoneNumberInputIsInvalid
              ? "Please enter valid phone number!"
              : ""}
          </p>
          {/* <TextField
            label="Phone Number"
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={props.enteredPhoneNumber}
            onChange={props.phoneNumberInputChangeHandler}
            onBlur={props.phoneNumberInputBlurHandler}
            error={props.phoneNumberInputIsInvalid}
            helperText={
              props.phoneNumberInputIsInvalid
                ? "Please enter valid phone number!"
                : ""
            }
          /> */}
        </div>
      </form>
    </div>
  );
}

export default BookingForm;
