import { Button, Container, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import CarouselContainer from "../CarouselContainer/CarouselContainer";
import AddressAndTimings from "../AddressAndTimings/AddressAndTimings";
import NavigationIcon from "@mui/icons-material/Navigation";
import "./Shop.css";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Accordian from "../Accordian/Accordian";

function Shop({ shopData }) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [servicesData, setServicesData] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (shopData && shopData.services && shopData.services.length > 0) {
      setServicesData(
        shopData.services.map((services) => {
          return {
            ...services,
            servicesList: services.servicesList.map((s) => {
              return { ...s, isSelected: false };
            }),
          };
        })
      );
    }
  }, [shopData]);

  useEffect(() => {
    const selected = [];
    let total = 0;
    servicesData.forEach((s) => {
      s.servicesList.forEach((l) => {
        if (l.isSelected) {
          selected.push(l);
          total = total + parseInt(l.amount);
        }
      });
    });
    if (selected.length > 0) {
      setSelectedServices(selected);
      setTotalPrice(total);
    }

    return () => {
      setSelectedServices([]);
      setTotalPrice(0);
    };
  }, [servicesData]);

  const serviceSelectionHandler = (id, groupId) => {
    setServicesData((prev) =>
      prev.map((s) => {
        if (s.groupId === groupId) {
          return {
            ...s,
            servicesList: s.servicesList.map((l) => {
              if (l.id === id) {
                return { ...l, isSelected: !l.isSelected };
              }
              return l;
            }),
          };
        }
        return s;
      })
    );
  };

  return (
    <Container maxWidth="xl">
      <CarouselContainer images={shopData.companyDetails.imagesList} />

      <AddressAndTimings shopData={shopData.companyDetails} />

      {/* Services */}
      <div className="service-container">
        {!mobileScreen ? (
          <VerticalTabs
            shopData={shopData}
            servicesData={servicesData}
            setServicesData={setServicesData}
            serviceSelectionHandler={serviceSelectionHandler}
          />
        ) : (
          <Accordian
            shopData={shopData}
            servicesData={servicesData}
            setServicesData={setServicesData}
            serviceSelectionHandler={serviceSelectionHandler}
          />
        )}
      </div>

      {/* Footer */}
      {selectedServices.length > 0 && (
        <div className="floating-footer">
          <div className="selectedServices">
            <p>
              {selectedServices.length} Services selected {shopData.companyDetails.currency}{totalPrice}
            </p>
          </div>
          <Button
            variant="contained"
            className="button"
            onClick={() => {
              navigate("/booking", {
                state: {
                  companyId: shopData.companyDetails.id,
                  servicesList: selectedServices,
                  shopName: shopData.companyDetails.name,
                  shopAddress: `${shopData.companyDetails.addressFirst}, ${shopData.companyDetails.postCode}`,
                  image: shopData.companyDetails.imagesList[0],
                  totalPrice: totalPrice,
                },
              });
            }}
          >
            <NavigationIcon sx={{ mr: 1 }} />
            Book Appointment
          </Button>
        </div>
      )}
    </Container>
  );
}

export default Shop;
