import React from "react";
import "./BookingSuccess.css";
import { Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function BookingSuccess() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <div className="booking-success">
        <div className="card">
          <div className="customCheckmark">
            <i className="checkmark">✓</i>
          </div>
          <h1>Success</h1>
          <p>
            Thanks for booking with us
            <br /> We hope to see you soon!
          </p>
        </div>
        <div className="buttons">
          <Button variant="contained">
            <Link to={"http://localhost:3000/auth/login"}>Create Account and Earn</Link>
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/shop");
            }}
          >
            Want to book more?
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default BookingSuccess;
