import React, { createContext, useEffect, useState } from "react";

const SubDomainContext = createContext({
  subdomain: null,
  removeSubDomain: () => {},
  mainUrl: "",
});

function SubDomainContextProvider({ children }) {
  const [subdomain, setSubDomain] = useState(null);
  const [mainUrl, setMainUrl] = useState("");

  useEffect(() => {
    const host = window.location.host; // gets the full domain of the app
    setMainUrl(localStorage.getItem("url"));
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) setSubDomain(arr[0]);
  }, []);

  const removeSubDomain = () => {
    setSubDomain(null);
  };
  return (
    <SubDomainContext.Provider value={{ subdomain, removeSubDomain, mainUrl }}>
      {children}
    </SubDomainContext.Provider>
  );
}

export { SubDomainContextProvider, SubDomainContext };
