import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosMainHelper";
import coverImage from "../assets/login-main.jpg";
import "./Auth.css";
import { AuthContext } from "../context/AuthContext";

function ResetPassword() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("token"));

  useEffect(() => {
    if (authCtx.isAuthenticated) {
      navigate("/home");
    }
  }, []);

  let {
    enteredInput: enteredPassword,
    inputInvalid: passwordInputIsInvalid,
    blurHandler: passwordInputBlurHandler,
    changeHandler: passwordInputChangeHandler,
  } = useInput("", (value) => value !== "" && value.length >= 8);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    passwordInputBlurHandler();

    if (!(enteredPassword.length >= 8) || !searchParams.get("token")) {
      return;
    }

    try {
      const response = await axiosInstance.post(`auth/reset-password`, {
        password: enteredPassword,
        token: searchParams.get("token"),
      });
      if (response.status === 200) {
        toast.success("Your password reset completed, login again!");
        navigate("/auth/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="app">
      <main className="login">
        <section className="section-login">
          <div className="section-main">
            <div className="section-login-1">
              <div className="section-login-1-main">
                <div className="section-login-1-img">
                  <img src={coverImage} alt="Hair salon" />
                </div>
              </div>
            </div>
            <div className="section-login-2">
              <div className="section-login-2-main">
                <h1 className="section-login-2-title">Reset Your Password</h1>
                <form
                  className="section-login-2-form"
                  onSubmit={resetPasswordHandler}
                >
                  <div className="login-form-3">
                    <label htmlFor="input-password">New Password</label>
                    <input
                      type="password"
                      id="input-password"
                      placeholder="At least 8 characters"
                      value={enteredPassword}
                      onChange={passwordInputChangeHandler}
                      onBlur={passwordInputBlurHandler}
                    />
                    {passwordInputIsInvalid && (
                      <p className="error-text">
                        Please enter at least 8 characters password!
                      </p>
                    )}
                  </div>
                  <div className="login-form-submit-btn">
                    <button type="submit">Reset Password</button>
                  </div>
                  <div className="login-form-5">
                    <p>
                      <Link to="/auth/login">Go to login page</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ResetPassword;
