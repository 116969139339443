import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "./DateCalendarPicker.css";
import dayjs from "dayjs";
import axiosMainInstance from "../../utils/AxiosMainHelper";

function DateCalenderPicker({
  onDateChange,
  dateValue,
  setShopBookingData,
  timeZone,
  apiPayload,
  URL,
}) {
  const fetchShopBookingData = async (dateValue) => {
    try {
      console.log(apiPayload.timeZone)
      const response = await axiosMainInstance.post(URL, {
        ...apiPayload,
        timeZone:timeZone,
        day: dayjs(dateValue).format("MMM/DD/YYYY"),
      });
      console.log("hereeee")
      if (response.status === 200) {
        setShopBookingData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        minDate={dayjs()}
        className="datePicker"
        value={dateValue}
        onChange={(newValue) => {
          onDateChange(newValue);
          fetchShopBookingData(newValue);
        }}
      />
    </LocalizationProvider>
  );
}

export default DateCalenderPicker;
