import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./VerticalTabs.css";
import TabPanel from "../TabPanel/TabPanel";
import ServicesList from "../ServicesList/ServicesList";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({
  servicesData,
  serviceSelectionHandler,
  shopData
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      className="tab-part"
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        margin: "30px",
      }}
    >
      <Tabs
        className="tab-title-name"
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {servicesData.map((service, index) => {
          return <Tab key={index} label={service.name} {...a11yProps(index)} />;
        })}
      </Tabs>
      {servicesData.map((service, index) => {
        return (
          <TabPanel
            key={service.groupId}
            value={value}
            index={index}
            className="tab-tab"
          >
            <ServicesList
              currency={shopData.companyDetails.currency}
              service={service}
              serviceSelectionHandler={serviceSelectionHandler}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
}
