import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { SubDomainContextProvider } from "./context/SubDomainContext";
import { AuthContextProvider } from "./context/AuthContext";

localStorage.setItem("url", window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SubDomainContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </SubDomainContextProvider>
  </React.StrictMode>
);
