import {Button, Container} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import axiosInstance from "../utils/AxiosMainHelper";
import "./Home.css";
import {useNavigate} from "react-router";
import {AuthContext} from "../context/AuthContext";
import "./CustomerBookings.css";

function CustomerBookings() {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [customerDetails, setCustomerDetails] = useState([]);
    const [type, setType] = useState("Upcoming Bookings");

    function authHeader() {
        const token = localStorage.getItem("token");
        if (token) {
            return {Authorization: 'Bearer ' + token};
        }
    }

    const fetchCustomerDetails = async () => {
        if (!authCtx.currentUser) {
            return;
        }
        try {
            const response = await axiosInstance.get(
                `customer/booking-details?userId=${authCtx?.currentUser?.id}`, {headers:authHeader()}
            );

            if (response?.status === 200) {
                setCustomerDetails(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCustomerDetails();
    }, [authCtx]);

    const filteredDetails =
        customerDetails.length > 0
            ? customerDetails.filter((s) => {
                let d1 = new Date(
                    `${s.serviceDetails.eventDate} ${s.serviceDetails.eventTime}`
                );
                let currentdate = new Date();

                if (type === "Upcoming Bookings") {
                    return d1.getTime() > currentdate.getTime();
                } else {
                    return d1.getTime() < currentdate.getTime();
                }
            })
            : [];
    console.log({filteredDetails});
    return (
        <Container maxWidth="xl">
            <div className="top-header-name">
                <h2 className="headerName">
                    Hi, Welcome back {authCtx?.currentUser?.name}
                </h2>
            </div>
            <div className="customerBookingWrapper">
                <div className="filterBookingTypes">
                    <Button
                        variant={type === "Upcoming Bookings" ? "contained" : "outlined"}
                        onClick={() => setType("Upcoming Bookings")}
                    >
                        Upcoming Bookings
                    </Button>
                    <Button
                        variant={type === "Past Bookings" ? "contained" : "outlined"}
                        onClick={() => setType("Past Bookings")}
                    >
                        Past Bookings
                    </Button>
                </div>

                <div className="allBookingsContainer">
                    {filteredDetails.map((detail) => {
                        return (
                            <div key={detail.id} className="singleBooking">
                                <div className="detailsWrapper">
                                    <div className="container">
                                        <p>Service Name</p>
                                        <h3>{detail.serviceDetails.name}</h3>
                                    </div>

                                    <div className="container">
                                        <p>Start Time</p>
                                        <h3>{detail.serviceDetails.startTime}</h3>
                                    </div>
                                </div>

                                <div className="detailsWrapper">
                                    <div className="container">
                                        <p>Shop Name</p>
                                        <h3>{detail.serviceProviderDetails.companyName}</h3>
                                    </div>

                                    <div className="container">
                                        <p>Service Provider</p>
                                        <h3>{detail.serviceProviderDetails.name}</h3>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Button
                variant="contained"
                className="loginBtn"
                onClick={() => {
                    authCtx.logout();
                    navigate("/home")
                }}
            >
                Logout
            </Button>
        </Container>
    );
}

export default CustomerBookings;
