import React from "react";
import dayjs from "dayjs";
import "./BookingDetails.css";

function BookingDetails({ apiPayload, staff, dateValue, selectedSlot,currency }) {
  return (
    <div className="booking-detailsWrapper">
      <div className="image">
        <img alt="Shop" src={apiPayload.image} width={"100%"} height={"100%"} />
      </div>
      <h4>{apiPayload.shopName}</h4>
      <p className="para">{apiPayload.shopAddress}</p>
      <hr />
      <div className="date-time-duration">
        <h4>
          {dayjs(dateValue).format("MMM / D / YYYY")}{" "}
          {selectedSlot ? `at ${selectedSlot}` : ""}
        </h4>
      </div>
      <hr />
      {apiPayload.servicesList.map((list) => {
        return (
          <React.Fragment key={list.id}>
            <div className="services-list-details">
              <div className="services">
                <p>{list.name}</p>
                <p>{currency}{list.amount}</p>
              </div>
              <p>{list.duration}min duration</p>
            </div>
            <hr />
          </React.Fragment>
        );
      })}
      {staff && (
        <>
          <p>{staff.staffName} (Staff Member)</p>
          <hr />
        </>
      )}

      <div className="total">
        <p>Total</p>
        <p>{currency}{apiPayload.totalPrice}</p>
      </div>
    </div>
  );
}

export default BookingDetails;
