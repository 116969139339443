import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import DateCalenderPicker from "../DateCalenderPicker/DateCalenderPicker";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

function AvailableSlots({
  staff,
  shopBookingData,
  dateValue,
  onDateChange,
  slotList,
  selectedSlot,
  setSelectedSlot,
  setShopBookingData,
  setSlotList,
  setStaff,
  apiPayload,
  shopData,
}) {
  const [tz, setTz] = useState(shopData.companyDetails.timeZone.value);
  useEffect(() => {
    setStaff(shopBookingData[0].staffId);
    setSlotList(
      shopBookingData.find((s) => s.staffId === shopBookingData[0].staffId)
        .availableSlots
    );
  }, [shopBookingData]);

  const handleStaffChange = (event) => {
    setStaff(event.target.value);
    setSlotList(
      shopBookingData.find((s) => s.staffId === event.target.value)
        .availableSlots
    );
  };

  return (
    <>
      <Box>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Choose an employee
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={staff}
            label="Choose an employee"
            onChange={handleStaffChange}
          >
            {shopBookingData.map((staff) => {
              return (
                <MenuItem key={staff.staffId} value={staff.staffId}>
                  {staff.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <div className="dateAndSlots">
        <div className="wrapperDate">
          <h2>Pick your date</h2>
          <div className="timezone-wrapper">
            <TimezoneSelect
              isDisabled={true}
              value={tz}
              onChange={setTz}
              timezones={{
                ...allTimezones,
                "America/Lima": "Pittsburgh",
                "Europe/Berlin": "Frankfurt",
              }}
            />
          </div>
          <DateCalenderPicker
            dateValue={dateValue}
            onDateChange={onDateChange}
            setShopBookingData={setShopBookingData}
            // apiPayload={apiPayload}
            URL={`booking/v2/slots`}
            apiPayload={{
              companyId: apiPayload.companyId,
              servicesList: apiPayload.servicesList.map((s) => s.id),
            }}
          />
        </div>
        {slotList.length === 0 && (
          <div className="wrapper">
            <div className="slot-title-part">
              <h2>No Available slots for selected date!</h2>
            </div>
          </div>
        )}
        {slotList.length > 0 && (
          <div className="wrapper">
            <div className="slot-title-part">
              <h2>Available slots</h2>
              <div className="slot-part-color-box">
                <h5>
                  <span></span>Available
                </h5>
                <h5>
                  <span></span>Selected
                </h5>
              </div>
            </div>
            <div className="slotsWrapper">
              {slotList.map((slot) => {
                return (
                  <Button
                    key={slot}
                    variant="contained"
                    className="slotBtn"
                    size="medium"
                    // color={slot === selectedSlot ? "primary" : "#fff"}
                    sx={{
                      border: `1px solid ${
                        slot !== selectedSlot ? "#1976d2" : "transparent"
                      }`,
                      background:
                        slot === selectedSlot ? "primary" : "transparent",
                      color: slot === selectedSlot ? "#fff" : "#000",
                    }}
                    onClick={() => {
                      setSelectedSlot((prev) => (prev === slot ? null : slot));
                    }}
                  >
                    {slot}
                  </Button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AvailableSlots;
