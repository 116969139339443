import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShopLayout from "./pages/ShopLayout";
import "./App.css";
import { useContext } from "react";
import HomeLayout from "./pages/HomeLayout";
import { SubDomainContext } from "./context/SubDomainContext";
import BookingLayout from "./pages/BookingLayout";
import BookingSuccessLayout from "./pages/BookingSuccessLayout";
import MainLayout from "./pages/MainLayout";
import NonShopLayout from "./pages/NonShopLayout";
import StaffWrapper from "./pages/StaffWrapper";
import ServicesWrapper from "./pages/ServicesWrapper";
import ClassesWrapper from "./pages/ClassesWrapper";
import SingleStaffWrapper from "./pages/SingleStaffWrapper";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import { AuthContext } from "./context/AuthContext";

// const shopRouter = ;

// const mainRouter = ;

function App() {
  const subDomainCtx = useContext(SubDomainContext);
  const authCtx = useContext(AuthContext);

  return (
    <div className="App">
      {subDomainCtx.subdomain ? (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: "/",
              element: <MainLayout />,
              children: [
                {
                  path: "/shop",
                  element: <ShopLayout />,
                },
                {
                  path: "/business",
                  element: <NonShopLayout />,
                  children: [
                    {
                      path: "/business/staff/:staffId",
                      element: <SingleStaffWrapper />,
                    },
                    {
                      path: "/business/staff",
                      element: <StaffWrapper />,
                    },
                    {
                      path: "/business/services",
                      element: <ServicesWrapper />,
                    },
                    {
                      path: "/business/classes",
                      element: <ClassesWrapper />,
                    },
                  ],
                },
              ],
            },
            {
              path: "/booking",
              element: <BookingLayout />,
            },
            {
              path: "/booking-success",
              element: <BookingSuccessLayout />,
            },
            {
              path: "/auth/signUp",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/admin/bookings"} />
              ) : (
                <SignUp />
              ),
            },
            {
              path: "/auth/login",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/admin/bookings"} />
              ) : (
                <Login />
              ),
            },
            {
              path: "/auth/forgot-password",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/home"} />
              ) : (
                <ForgotPassword />
              ),
            },
            {
              path: "/auth/reset-password",
              element: <ResetPassword />,
            },
            {
              path: "/admin/bookings",
              element: !authCtx.isAuthenticated ? (
                <Navigate to={"/auth/login"} />
              ) : (
                <Dashboard />
              ),
            },
            {
              path: "*",
              element: <Navigate to={"/shop"} />,
            },
          ])}
        />
      ) : (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: "/",
              element: <Navigate to={"/home"} />,
            },
            {
              path: "/home",
              element: <HomeLayout />,
            },
            {
              path: "/auth/signUp",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/admin/bookings"} />
              ) : (
                <SignUp />
              ),
            },
            {
              path: "/auth/login",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/admin/bookings"} />
              ) : (
                <Login />
              ),
            },
            {
              path: "/auth/forgot-password",
              element: authCtx.isAuthenticated ? (
                <Navigate to={"/home"} />
              ) : (
                <ForgotPassword />
              ),
            },
            {
              path: "/auth/reset-password",
              element: <ResetPassword />,
            },
            {
              path: "/admin/bookings",
              element: !authCtx.isAuthenticated ? (
                <Navigate to={"/auth/login"} />
              ) : (
                <Dashboard />
              ),
            },
            {
              path: "*",
              element: <Navigate to={"/home"} />,
            },
          ])}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
