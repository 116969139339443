import React from "react";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import "./AddressAndTimings.css";

function AddressAndTimings({shopData, showContent = true}) {
    return (
        <div className="about-content">
            {showContent && (
                <div className="content-details">
                    <h2>About {shopData.name}</h2>
                    <p className="discription">
                        {shopData.descriptionFirst}
                    </p>
                    <p className="discription">
                        {shopData.descriptionSecond}
                    </p>
                    {/*<p className="discription">*/}
                    {/*  With styles befitting millennial males and distinguished gentlemen*/}
                    {/*  alike, Machete Barber Shop awaits your visit just a 5-minute walk*/}
                    {/*  from Elephant and Castle Underground Station.*/}
                    {/*</p>*/}
                </div>
            )}
            <div className={`addTimeContainer ${showContent ? "position" : ""}`}>
                        <div className="address">
                            <div className="locationIconText">
                                <div className="locationIcon">
                                    <LocationOnSharpIcon sx={{fontSize: "30px"}}/>
                                </div>
                                <h2>Location</h2>
                            </div>
                            <div className="locationDetails">
                                <div>
                                    <p>{shopData.addressFirst}</p>
                                    <p>
                                        {shopData.postCode} {shopData.state}
                                    </p>
                                </div>
                            </div>
                        </div>
                <div className="timing">
                    <h2>Timings</h2>

                    {shopData.businessHoursList.map((hours) => {
                        return (
                            <div key={hours.id} className="timimgContainer">
                                <p>{hours.day}</p>
                                <p>
                                    {hours.startTime} - {hours.endTime}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default AddressAndTimings;
