import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosMainInstance from "../utils/AxiosMainHelper";
import { SubDomainContext } from "../context/SubDomainContext";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import Booking from "../components/Booking/Booking";
import dayjs from "dayjs";
import BackDrop from "../components/BackDrop/BackDrop";

function BookingLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { subdomain } = useContext(SubDomainContext);
  const [shopData, setShopData] = useState(null);
  const [shopBookingData, setShopBookingData] = useState(null);

  const fetchShopBySubDomain = async () => {
    try {
      const response = await axiosMainInstance.get(
        `external/v2/company?name=${subdomain}`
      );
      if (response?.status === 200) {
        setShopData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShopBookingData = async () => {
    try {
      const response = await axiosMainInstance.post(`booking/v2/slots`, {
        companyId: location.state.companyId,
        servicesList: location.state.servicesList.map((s) => s.id),
        day: dayjs().format("MMM/DD/YYYY"),
      });
      if (response.status === 200) {
        setShopBookingData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      fetchShopBookingData();
      fetchShopBySubDomain();
    } else {
      navigate("/");
    }
  }, [location]);

  if (!shopData || !shopBookingData) {
    return <BackDrop />;
  }

  return (
    <>
      {shopData && (
        <ResponsiveAppBar subdomain={subdomain} shopData={shopData} />
      )}
      {shopBookingData && (
        <Booking
          shopData={shopData}
          shopBookingData={shopBookingData}
          setShopBookingData={setShopBookingData}
          apiPayload={location.state}
        />
      )}
    </>
  );
}

export default BookingLayout;
