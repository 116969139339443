import React from "react";
import { NavLink } from "react-router-dom";

function SidebarLinks({ sideBarLinks, sidebarHandler }) {
  return (
    <div className="side-links">
      {sideBarLinks.map((s) => {
        return (
          <NavLink
            key={s.id}
            className={({ isActive }) =>
              `link-container ${isActive ? " selected" : ""}`
            }
            to={s.link}
            // className={`link-container ${s.active ? "selected" : ""}`}
            onClick={() => sidebarHandler(s.id)}
          >
            <div className="icon">{s.icon}</div>
            <div>{s.name}</div>
          </NavLink>
        );
      })}
    </div>
  );
}

export default SidebarLinks;
