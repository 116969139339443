import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useInput from "../hooks/useInput";
import axiosInstance from "../utils/AxiosMainHelper";
import coverImage from "../assets/login-main.jpg";
import "./Auth.css";
import { AuthContext } from "../context/AuthContext";

function ForgotPassword() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.isAuthenticated) {
      navigate("/home");
    }
  }, []);

  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput("", (value) => value !== "");

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    emailInputBlurHandler();

    if (!enteredEmail) {
      return;
    }

    try {
      const response = await axiosInstance.get(
        `auth/initiate-password-request?email=${enteredEmail}`
      );
      if (response.status === 200) {
        toast.success("An email sent for reset your password!");
        navigate("/auth/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="app">
      <main className="login">
        <section className="section-login">
          <div className="section-main">
            <div className="section-login-1">
              <div className="section-login-1-main">
                <div className="section-login-1-img">
                  <img src={coverImage} alt="Hair salon" />
                </div>
              </div>
            </div>
            <div className="section-login-2">
              <div className="section-login-2-main">
                <h1 className="section-login-2-title">Forgot Password</h1>
                <form
                  className="section-login-2-form"
                  onSubmit={forgotPasswordHandler}
                >
                  <div className="login-form-1">
                    <label htmlFor="input-email">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      placeholder="john@example.com"
                      value={enteredEmail}
                      onChange={emailInputChangeHandler}
                      onBlur={emailInputBlurHandler}
                    />
                    {emailInputIsInvalid && (
                      <p className="error-text">
                        Please enter valid email address!
                      </p>
                    )}
                  </div>
                  <div className="login-form-submit-btn">
                    <button type="submit">Submit</button>
                  </div>
                  <div className="login-form-5">
                    <p>
                      <Link to="/auth/login">Go to login page</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ForgotPassword;
