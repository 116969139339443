import { Box, Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./Booking.css";
import StepperMain from "../Stepper/Stepper";
import AvailableSlots from "../AvailableSlots/AvailableSlots";
import axiosInstance from "../../utils/AxiosMainHelper";
import BookingForm from "../BookingForm/BookingForm";
import useInput from "../../hooks/useInput";
import BookingDetails from "../BookingDetails/BookingDetails";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const steps = ["Available slots", "User details"];

function Booking({
  shopBookingData,
  setShopBookingData,
  apiPayload,
  shopData,
}) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [staff, setStaff] = React.useState("");
  const [dateValue, setDateValue] = React.useState(dayjs());
  const [slotList, setSlotList] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);

  let {
    enteredInput: enteredName,
    inputInvalid: nameInputIsInvalid,
    blurHandler: nameInputBlurHandler,
    changeHandler: nameInputChangeHandler,
  } = useInput("", (value) => value !== "");
  let {
    enteredInput: enteredEmail,
    inputInvalid: emailInputIsInvalid,
    blurHandler: emailInputBlurHandler,
    changeHandler: emailInputChangeHandler,
  } = useInput(
    "",
    (value) =>
      value !== "" &&
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
  );

  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  const [enteredPhoneNumberBlur, setEnteredPhoneNumberInputBlur] =
    useState(false);

  const validate = (value) => {
    return value !== "" && value.length >= 10 && value.length <= 15;
  };
  const enteredInputIsValid =
    enteredPhoneNumberBlur && validate(enteredPhoneNumber);
  const phoneNumberInputIsInvalid =
    !enteredInputIsValid && enteredPhoneNumberBlur;

  const phoneNumberInputChangeHandler = (phone) => {
    setEnteredPhoneNumber(phone);
  };

  const phoneNumberInputBlurHandler = () => {
    setEnteredPhoneNumberInputBlur(true);
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      nameInputBlurHandler();
      emailInputBlurHandler();
      phoneNumberInputBlurHandler();

      if (!enteredName || !enteredEmail || !enteredPhoneNumber) {
        return;
      }
      setLoading(true);

      const payload = {
        staffId: staff,
        serviceId: apiPayload.servicesList[0].id,
        companyId: apiPayload.companyId,
        serviceList: apiPayload.servicesList.map((s) => s.id),
        customerDto: {
          name: enteredName,
          phone: enteredPhoneNumber,
          email: enteredEmail,
        },
        day: dayjs(dateValue).format("MMM/DD/YYYY"),
        timeSlot: selectedSlot,
      };
      try {
        const response = await axiosInstance.post(`external/booking`, payload);

        if (response.status === 200) {
          setLoading(false);
          navigate("/booking-success");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onDateChange = (date) => {
    setDateValue(date);
  };

  const nameProp = {
    enteredName,
    nameInputBlurHandler,
    nameInputChangeHandler,
    nameInputIsInvalid,
  };
  const emailProp = {
    enteredEmail,
    emailInputBlurHandler,
    emailInputChangeHandler,
    emailInputIsInvalid,
  };
  const phoneNumberProp = {
    enteredPhoneNumber,
    phoneNumberInputBlurHandler,
    phoneNumberInputChangeHandler,
    phoneNumberInputIsInvalid,
    enteredInputIsValid,
    validate,
  };

  return (
    <Container maxWidth="xl">
      <div className="booking-wrapper">
        <div className="booking-criteria">
          <StepperMain steps={steps} activeStep={activeStep} />

          {activeStep === 0 ? (
            <AvailableSlots
              staff={staff}
              shopBookingData={shopBookingData}
              dateValue={dateValue}
              onDateChange={onDateChange}
              slotList={slotList}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              setShopBookingData={setShopBookingData}
              setSlotList={setSlotList}
              setStaff={setStaff}
              apiPayload={apiPayload}
              shopData={shopData}
            />
          ) : (
            <BookingForm
              {...nameProp}
              {...emailProp}
              {...phoneNumberProp}
              showHeader
            />
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2, px: 2 }}>
            {activeStep === 1 && (
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />

            {selectedSlot && (
              <LoadingButton
                onClick={handleNext}
                variant="contained"
                disabled={loading}
                loading={loading}
                loadingIndicator="Booking..."
              >
                {activeStep === steps.length - 1
                  ? "Confirm Booking"
                  : "Confirm Slot Selection"}
              </LoadingButton>
            )}
          </Box>
        </div>
        <div className="booking-details">
          <BookingDetails
            staff={staff ? shopBookingData.find((s) => s.id === staff) : null}
            dateValue={dateValue}
            selectedSlot={selectedSlot}
            apiPayload={apiPayload}
            currency={shopData.companyDetails.currency}
          />
        </div>
      </div>
    </Container>
  );
}

export default Booking;
