import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ServicesList from "../ServicesList/ServicesList";

export default function Accordian({ servicesData, serviceSelectionHandler,shopData }) {
  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="accordianCustom">
      {servicesData.map((service, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === index + 1}
            onChange={handleChange(index + 1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {service.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ServicesList
                currency={shopData.companyDetails.currency}
                service={service}
                serviceSelectionHandler={serviceSelectionHandler}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
